import {
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    updateDoc,
    doc,
  } from "@firebase/firestore";
import { firestore } from "./firebase";

export const getAllOngoingProjects = async () => {
    try {
      const getData = collection(firestore, "research-ongoing-projects");
      const snapshot = await getDocs(getData);
      const ongoingProjects = [];
      snapshot.forEach((doc) => {
        ongoingProjects.push({
            id: doc.id,
            title: doc.data().title,
            subtitle: doc.data().subtitle,
            line_one: doc.data().line_one,
            line_two: doc.data().line_two,
        });
      });
      console.log("All ongoingProjects fetched:", ongoingProjects);
      return ongoingProjects;
    } catch (error) {
      throw new Error( error);
    }
  };

export const addOngoingProject = async (title, subtitle, line_one, line_two) => {
    let ongoingProject = {
      title: title,
      subtitle: subtitle,
      line_one: line_one,
      line_two: line_two,
    };
  
    try {
      const ongoingProjectDocRef = await addDoc(collection(firestore, "research-ongoing-projects"), ongoingProject);
      const ongoingProjectId = ongoingProjectDocRef.id;
      console.log("Publication added successfully:" + ongoingProjectId);
  
      
    } catch (error) {
      console.error("Error adding ongoingProject", error);
    }
  };

  export const deleteOngoingProject = async (ref) => {
    try {
      const ongoingProjectDocRef = doc(firestore,"research-ongoing-projects", ref);
      await deleteDoc(ongoingProjectDocRef);
      console.log("ongoingProject deleted successfully:", ref);
    } catch (error) {
        console.error("Error deleting ongoingProject", error);
    }
  };