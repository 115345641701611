import React from 'react'
import { useState } from 'react'
import { TextField, Button,Typography, Box, Modal } from '@mui/material'
import { addArticle } from '../Backend/news';
import Snackbar from '@mui/material/Snackbar';


function AddNewsModal(props) {
    const handleClose = () => props.setOpen(false);
    const [isSnackbar, setIsSnackbar] = useState(false);

    const closeSnackbar = () => {
      setIsSnackbar(false);
    };

    const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    const article = {
      author: data.get('author'),
      content: data.get('content'),
      date: data.get('date'),
      link: data.get('link'),
      title: data.get('title'),
      image:data.get('image')
    };
    const {author,content,date,link,title,image} = article;
    addArticle(author,content,date,link,title,image);
    setIsSnackbar(true);
    setTimeout(() => {
      handleClose();
      window.location.reload();
  }, 2000);
  };

  return (
    <Modal
    open={props.open}
    onClose={handleClose}
    sx={{display:'flex', justifyContent:'center', 
        padding:'2em', overflow:'auto'
    }}
    >
      <Box
        sx={{
            background:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'90vw',
            height:'fit-content',
            borderRadius:'5px'
            
        }}
      >
        <div style={{width:'100%', background:'#5D1725',
             display:'flex', justifyContent:'center', 
             color:'white', padding:'1em',borderTopLeftRadius:'5px', borderTopRightRadius:'5px'}}>
            <Typography component="h1" variant="h5" >
            NEWS INFORMATION
            </Typography>
        </div>
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'100%'
        }}
        >
            <div style={{width:'100%', padding:'1em'}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="author"
                    label="Author"
                    name="author"
                    autoComplete="author"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="content"
                    label="Summary"
                    name="content"
                    autoComplete="content"
                    multiline
                    inputProps={{maxLength:400}}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="link"
                    label="Link"
                    name="link"
                    autoComplete="link"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="date"
                    label="Date"
                    name="Date"
                    autoComplete="date"
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="image"
                    name="image"
                    type="file"

                />

                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background:'black' }}
                >
                Submit
                </Button>
            </div>
        </Box>
        <Snackbar
          open={isSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Added News Article!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      </Box>
    </Modal>
  )
}

export default AddNewsModal;
