import React from 'react';
import './App.css';       //importing app.css directly links the css file; do not have to link like traditional HTML.
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Routing from './Routing';


function App() {
  return (
    <div className="App">
      <Router>
        <Routing />
      </Router>
    </div>
  );
}

export default App;