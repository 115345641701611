import React, { useState, useEffect } from 'react'
import '../Styles/DecoratedHeadshot.css'

function DecoratedHeadshot({image}) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (image) {
      setImageUrl(URL.createObjectURL(image));
    } 
  }, [image]);
  return (
    <div style={{display:'flex', justifyContent:'center', position:'relative', width:'35%', height:'fit-content', alignItems:'center'}}>
      <img className= 'outer-headshot-img' src={'/headshot-placeholder.png'} />
      <img className= 'inner-headshot-img' src={imageUrl} />
    </div>
  )
}

export default DecoratedHeadshot
