import React, { useState } from 'react'
import { TextField, Button,Typography, Box, Modal } from '@mui/material'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Backend/firebase';     
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


function AdminLoginModal(props) {

    const [isSnackbar, setIsSnackbar] = useState(false);
    const [status, setStatus] = useState("");
    const handleClose = () => {
        props.setOpen(false);           //sets open false and closes the AdminLoginModal component.
        setStatus("");
        setIsSnackbar(false);
    };

    const handleAdminLogin = () => {
        setTimeout(() => {
            handleClose();          
            window.location.reload();
        }, 1000);
    };

    const handleSubmit = (event) => {
        event.preventDefault();         //prevents the default form submission behavior i.e. refreshing the page which is 
                                        //essential in SPAs built within the react.
        const data = new FormData(event.currentTarget);         //this returns the formData object with all the data of inputfields

        //data.get('email') gets the email property from the formData object of the line 29.
        signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
        .then((user) => {           
            // if success, the promise resolves with the object i.e. user in this context.
            console.log(user);          
            console.log("admin has logged in!");
            setStatus("success");
            setIsSnackbar(true);        //shows up the success snackbar if true.
            handleAdminLogin();         //it now closes the modal and reloads the page as triggers the handleAdminlogin function.
        }).catch((error) => {
            //if error, the promise throws the error object.
            console.log("error signing in:" + error);
            setStatus("error");
            setIsSnackbar(true);        //if error shows the error snackbar
        })
      };

      const closeSnackbar = () => {
        setIsSnackbar(false);
      };

  return (
    <Modal
    open={props.open}
    onClose={handleClose}           //triggers the function 'handleClose' whenver the user clicks outside of the modal or clicks
                                    //esc key. The react knows the event due to the onClose attribute.
    sx={{display:'flex', justifyContent:'center', 
        padding:'2em', overflow:'auto'
    }}
    >
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
            background:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'45vw',
            height:'fit-content',
            borderRadius:'5px'
            
        }}
      >
        <div style={{width:'100%', background:'black',
             display:'flex', justifyContent:'center', 
             color:'white', padding:'1em',borderTopLeftRadius:'5px', borderTopRightRadius:'5px'}}>
            <Typography component="h1" variant="h5" >
            ADMIN LOGIN
            </Typography>
        </div>
        <div style={{width:'100%', padding:'1em'}}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="Email"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type="password"
                name="password"
                autoComplete="password"
            />
            
                
            <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, background:'black' }}
            >
            Submit
            </Button>
        </div>
        <Snackbar
        open={isSnackbar}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{vertical: 'bottom',horizontal: 'right' }} // Position the Snackbar on the right side
        >
        <Alert
            onClose={closeSnackbar}         //if the user clicks out of the modal or esc key, the snackbar closes rightaway.
            severity={status}       // set the color of the alert snackbar i.e. either green or red.
            variant="filled"
            sx={{ width: '100%' }}
        >
            Admin Sign-in {status}
        </Alert>
        </Snackbar>
      </Box>
      
    </Modal>
  )
}

export default AdminLoginModal
