// src/CardFlip.js
import React, { useState } from 'react';
import '../Styles/CardFlip.css';

const CardFlip = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`card-container ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
      <div className="card">
        <div className="front">
          {props.frontContent}
        </div>
        <div className="back">
          {props.backContent}
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
