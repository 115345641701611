import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import "../Styles/Footer.css";

function Footer() {
    const linkToggle = (link) => {
        window.open(link, "_blank");
        };
  return (
    <div style={{ width:'100%', display:'flex', flexDirection:'column'}}>
        <div style={{background:'#5D1725', width:'100%', display:'flex', padding:'0.8em',
             justifyContent:'center', gap:'1.5em', borderBottom:'4px solid #322023'}}>
            <FacebookIcon 
            onClick={() =>
            linkToggle(
                "https://www.facebook.com/MSUCALS/"
            )
            }
            sx={{color:'white', fontSize:'1.6em', cursor:'pointer'}}/>
            <LinkedInIcon 
            onClick={() =>
            linkToggle(
                "https://www.linkedin.com/in/pjhamsu/"
            )
            }
            sx={{color:'white', fontSize:'1.6em', cursor:'pointer'}}/>
            <XIcon 
            onClick={() =>
            linkToggle(
                "https://twitter.com/MSU_AG"
            )
            }
            sx={{color:'white', fontSize:'1.6em', cursor:'pointer'}}/>
            <YouTubeIcon 
            onClick={() =>
            linkToggle(
                "https://www.youtube.com/user/MSUAgandLifeSciences"
            )
            }
            sx={{color:'white', fontSize:'1.6em', cursor:'pointer'}}/>
        </div>
        <div style={{background:'black', display:"flex", flexDirection:'column', padding:'3em', gap:'3em'}}>
            <div className='footer-top-div'>
                <div className='footer-contact-div'>
                    <img src="/msstate_square_white.svg"  alt="msstate-square-white-logo" className='msstate-square-white-logo'/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', gap:'3px'}}>
                        <p className='footer-univ-name-text'>
                            Mississippi State University
                        </p>
                        <p className='footer-grey-text'>
                            Department of Plant and Soil Sciences
                        </p>
                        <p className='footer-grey-text'>
                            Mississippi State, MS 39762
                        </p>
                        <p className='footer-grey-text'>
                            P: (662) 325-2311
                        </p>
                    </div>
                </div>
                <div className='footer-link-div'>
                    <div style={{display:'flex', flexDirection:'column', gap:'8px'}}>
                        <p className='footer-link-text'
                        onClick={() =>
                            linkToggle(
                                "https://www.emergency.msstate.edu/"
                            )
                            }
                        >
                            Emergencies
                        </p>
                        <p
                        className='footer-link-text' 
                        onClick={() =>   
                        linkToggle(
                            "https://www.msstate.edu/its-status/"
                        )
                        }
                        >
                            IT Status
                        </p>
                        <p
                        className='footer-link-text'
                        onClick={() =>
                        linkToggle(
                            "https://www.msstate.edu/jobs/"
                        )
                        }
                         >
                            MSSTATE Jobs
                        </p>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
                        <p 
                        className='footer-link-text'
                        onClick={() =>
                        linkToggle(
                            "https://www.msstate.edu/academics/"
                        )
                        }
                        >
                            Academics
                        </p>
                        <p 
                        className='footer-link-text'
                         onClick={() =>
                            linkToggle(
                                "https://www.msstate.edu/research/"
                            )
                            }
                        >
                            Research
                        </p>
                        <p 
                        className='footer-link-text'
                        onClick={() =>
                            linkToggle(
                                "https://www.msstate.edu/libraries/"
                            )
                            }
                        >
                            Libraries
                        </p>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
                        <p
                        className='footer-link-text'
                        onClick={() =>
                            linkToggle(
                                "https://www.msstate.edu/outreach/"
                            )
                            } 
                        >
                            Outreach
                        </p>
                        <p
                        className='footer-link-text'
                        onClick={() =>
                            linkToggle(
                                "https://www.msstate.edu/athletics/"
                            )
                            } 
                         >
                            Athletics
                        </p>

                        <p
                        className='footer-link-text'
                        onClick={() =>
                        linkToggle(
                            "https://www.msstate.edu/giving/"
                        )
                        }  
                        >
                            Giving
                        </p>
                    </div>
                </div>
                <div className='footer-hortz-img-div'>
                    <img src="/msstate_horizontal_white.svg"  alt="msstate-horizontal-white-logo" width={'90%'}/>

                </div>
            </div>
            <div className='footer-bottom-div'>
                <div className='footer-sec-link-div'>
                    <p
                    onClick={() =>
                        linkToggle(
                            "http://msstate.edu/customer-service"
                    )
                    }
                    className='footer-grey-text-small-underline'>Customer Service</p>
                    <p className='footer-grey-text-small'>|</p>
                    <p 
                        onClick={() =>
                        linkToggle(
                            "http://msstate.edu/eeo-statement"
                    )
                    }
                    className='footer-grey-text-small-underline'>EEO Statement</p>
                    <p className='footer-grey-text-small'>|</p>
                    <p 
                    onClick={() =>
                        linkToggle(
                            "http://msstate.edu/ethics-line"
                    )
                    }
                    className='footer-grey-text-small-underline'>Ethics Line</p>
                    <p className='footer-grey-text-small'>|</p>
                    <p 
                    onClick={() =>
                        linkToggle(
                            "http://msstate.edu/legal"
                    )
                    }
                    className='footer-grey-text-small-underline'>Legal Notices</p>
                </div>   
                <div>
                    <p className='footer-grey-text-small-no-pointer'>Copyright © 2024 </p>
                    <p className='footer-grey-text-small-underline'
                    onClick={() =>
                        linkToggle(
                            "https://www.cals.msstate.edu/"
                    )
                    }
                    >College of Agriculture and Life Sciences</p>
                    <p className='footer-grey-text-small-no-pointer'>, </p>
                    <p 
                    onClick={() =>
                        linkToggle(
                            "https://www.dafvm.msstate.edu/"
                    )
                    }
                    className='footer-grey-text-small-underline'>Division of Agriculture, Forestry and Veterinary Medicine</p>
                    <p className='footer-grey-text-small-no-pointer'>, Mississippi State University. All Rights Reserved.</p>
                </div>
                <div style={{marginTop:'1.5em'}}>
                    <p className='footer-grey-text-small-no-pointer' 
                    >
                        Mississippi State University is an equal opportunity institution. 
                        Discrimination in university employment, programs or activities
                        based on race, color, ethnicity, sex, pregnancy, religion, 
                        national origin, disability, age, sexual orientation, gender identity,
                        genetic information, status as a U.S. veteran, or any other status
                        protected by applicable law is prohibited. For more information, 
                        please contact the 
                    </p>
                    <p className='footer-grey-text-small-underline'> Office of Compliance and Integrity.</p>
                </div>
            </div>
        </div>   
    </div>
  )
}

export default Footer
