import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import DrawerNavbar from '../Components/DrawerNavbar';
import {Button} from '@mui/material';
import AddProjectsModal from '../Components/AddProjectsModal';
import "../Styles/Research.css";
import CardFlip from '../Components/CardFlip';
import { auth } from '../Backend/firebase';
import { signOut } from "firebase/auth"; 
import { Tooltip } from '@mui/material';
import { Snackbar } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteAlert from '../Components/DeleteAlert';
import {getAllOngoingProjects } from '../Backend/research';
import Footer from '../Components/Footer';

function Research() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteRef, setDeleteRef] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const ongoingProjectsList = await getAllOngoingProjects();
      setOngoingProjects(ongoingProjectsList);
      console.log("user: " + auth.currentUser);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {      //handles if the user stays out of the tab for too long.
      if (document.visibilityState === "hidden") {      //document.visibilityState returns either 'visible' or 'hidden'
        signOut(auth)
          .then(() => {
            console.log("User signed out");
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      }
    };

    window.addEventListener("visibilitychange",handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleAlert = (id) => {
    console.log("clicked delete button");
    setOpenAlert(true);
    console.log("alert: " + openAlert);
    setDeleteRef(id);
}

const closeSnackbar = () => {
setIsDeleted(false);
};


  const frontContent1 = (
    <div style={{ width: '100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
      <img src='/research-topic-1-img.png'  alt='research-topic-1-img'
      className='research-topic-img'/>
      <h2 className='front-research-topic-title'>
          Agricultural Sustainability
      </h2>
    </div>
  );
  const frontContent2 = (
    <div style={{ width: '100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
      <img src='/research-topic-2-img.png'  alt='research-topic-2-img'
      className='research-topic-img'/>
      <h2 className='front-research-topic-title'>
          Decision Support System
      </h2>
    </div>
  );
  const frontContent3 = (
    <div style={{ width: '100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
      <img src='/research-topic-3-img.png'  alt='research-topic-3-img'
      className='research-topic-img'/>
      <h2 className='front-research-topic-title'>
          Geospatial and Digital Agriculture
      </h2>
    </div>
  );

  const backContent1 = (
    <div style={{ width: '100%', display:'flex',
    flexDirection:'column', justifyContent:'center',
    alignItems:'center', textAlign:'center',  paddingRight:'0.8em', paddingLeft:'0.8em'}}>
      <h3 className='back-research-topic-title'>
          Agricultural Sustainability
      </h3>
      <div className='title-bar'/>
      <p className='research-topic-text'>
        The urgency of climate change cannot be overstated. It
        disrupts weather patterns, increases extreme weather
        events, and alters water availability, all threatening
        agricultural productivity and sustainability. Our team is
        dedicated to understanding the multifaceted impacts of
        climate change on agriculture and developing innovative
        solutions to promote sustainable agricultural practices
        under these changing conditions.
      </p>
    </div>
  );
  const backContent2 = (
    <div style={{ width: '100%', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'
    , textAlign:'center', paddingRight:'0.8em', paddingLeft:'0.8em'}}>
      <h3 className='back-research-topic-title'>
          Decision Support System
      </h3>
      <div className='title-bar'/>
      <p  className='research-topic-text'>
        We are at the forefront of developing and evaluating
        innovative decision support systems. These systems are
        not only tailored to address the specific needs of
        agricultural production but also integrate advanced data
        sources from sensors, weather stations, satellite imagery,
        and historical farm records within the DSS framework. We
        develop the user-centered design and interface by
        integrating cutting-edge technologies such as machine
        learning and artificial intelligence (AI) with crop simulation models.
      </p>
    </div>
  );
  const backContent3 = (
    <div style={{ width: '100%', display:'flex',
    flexDirection:'column',alignItems:'center', justifyContent:'center', textAlign:'center',  paddingRight:'0.8em', paddingLeft:'0.8em'}}>
      <h3 className='back-research-topic-title'>
          Geospatial and Digital Agriculture
      </h3>
      <div className='title-bar'/>
      <p  className='research-topic-text'>
        Our research uses remote sensing (hyperspectral and
          thermal) and crop simulation models to monitor crop health,
          assess soil quality, or map agricultural land use and
          integrate these tools with precision agriculture, which
          utilizes data-driven insights to optimize resource use and
          improve crop yields.
      </p>
    </div>
  );

  return (
    <motion.div
      className="main-research"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%", transition: { duration: 1.5 } }}
      exit={{ opacity: 0 }}
    >
      {auth.currentUser && ( //renders this if the user is signed in.
        <div className="sticky-div">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => setOpenModal(true)}
            sx={{ mt: 3, mb: 2, background:'black', margin:'0' }}
            >
            Add Projects
          </Button>
        </div>
      )}
      <AddProjectsModal
        open={openModal}
        setOpen={setOpenModal} //to manipulate the state of the open variable through the child component.
      />
      <DeleteAlert
        open={openAlert}
        setOpen={setOpenAlert}
        id={deleteRef}
        setIsDeleted={setIsDeleted}
        type={"project"}
      />
      <Header />
      {isMobile ? <DrawerNavbar /> : <Navbar />}

      <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', position:'relative', marginBottom:'3.5em'}}>
        <img src='/research-img.png'  alt='research-img' className='group-img'/>
        <h2 className='pic-overlay-text'
        >RESEARCH
        </h2>
      </div>
      <div>
        <div className='title-div'>
            <h2 className='title-text'>OVERVIEW</h2>
        </div>
        <div className='title-bar'/>
        <div className='research-overview-div'>
          <p className='research-text'>
            Research at our lab focuses on investigating the influence of climate
            and agronomic management on crop growth and development. We seek to
            understand the complexities of agricultural systems, integrating crop
            simulation models, remote sensing, and climate forecasting to
            formulate decision-support systems for better management strategies of
            inputs in crop production systems.
          </p>
        </div>
      </div>
      <div className='main-research-topic-div'>
        <div className='research-topic-div'>
          <CardFlip frontContent={frontContent1} backContent={backContent1}/>
        </div>
        <div className='research-topic-div'>
          <CardFlip frontContent={frontContent2}  backContent={backContent2}/>
        </div>
        <div className='research-topic-div'>
          <CardFlip frontContent={frontContent3}  backContent={backContent3}/>
        </div>
      </div>
      <div>
        <div className='title-div'>
          <h2 className='title-text'>ONGOING PROJECTS</h2>
        </div>
        <div className='title-bar'/>
        <div style={{marginTop:'1.8em', display:'flex', paddingRight:'1.5em'}}>
          <ol type="numbers">
                {ongoingProjects.length > 0 ? (
                  ongoingProjects.map((project, index) => (
                    <div style={{display:'flex', gap:'0.8em', marginBottom:'2em'}}>
                      <li></li>
                      <div style={{display:'flex', flexDirection:'column'}}>
                        <h3 className='ongoing-project-title'>
                          {project.title}
                        </h3>
                        <p className='ongoing-project-participant-text'>
                          {project.subtitle}
                        </p>
                        <p className='plain-ongoing-project-text'>
                          {project.line_one}
                        </p>
                        <p className='plain-ongoing-project-text'>{project.line_two}</p>
                      </div>
                      {auth.currentUser && (
                        <div onClick={() => handleAlert(project.id)}
                        className='research-delete-icon-div'>
                            <Tooltip title='Delete' placement='bottom'>
                                <DeleteForeverIcon sx={{fontSize:'2.56y                     `vw'}} />
                            </Tooltip>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <h3 className='ongoing-project-title'>
                    No ongoing projects available.
                  </h3>
                )}
          </ol>
        </div>
      </div>
      <Snackbar
        open={isDeleted}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message="Deleted Project"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
      />
    <Footer/>
    </motion.div>
  );
}

export default Research;