import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { Snackbar } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Tooltip } from "@mui/material";
import { fetchedImages } from "../Pages/Members";
import copy from 'copy-to-clipboard';


const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #5D1725",
  borderRadius: "0.5em",
  boxShadow: "5px 5px 0px 2px #5D1725",
  overflow: "auto",
  padding: "1em !important",
  "&:focus": {
    outline: "none",
  },
};

const contactIcon = {
  color: "#5D1725",
  fontSize: "2vw",
  "&:hover": {
    color: "black",
    cursor: "pointer",
  },
  "@media (max-width: 500px)": {
    fontSize: "4vw",
  },
};

const emailContactIcon = {
  color: "#5D1725",
  fontSize: "2vw",
  "@media (max-width: 500px)": {
    fontSize: "4vw",
  },
};

function ProfileModal(props) {
  const [message, setMessage] = useState(false);

  const handleClick = () => {
    // if (navigator.clipboard && navigator.clipboard.writeText) {
    //   navigator.clipboard.writeText(props.profile.email);
    //   setMessage(true);

    copy(props.profile.email);
    setMessage(true);
    };

  const handleClose = () => props.setOpen(false);

  const closeSnackbar = () => {
    setMessage(false);
  };

  const linkToggle = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="main-modal-div"
    >
      <Box sx={modalStyle}>
        <div className="outer-modal-div">
          <CloseIcon onClick={handleClose} sx={contactIcon} />
        </div>
        <div className="outer-bio-div">
          <div className="bio-div">
            <img
              src={
                fetchedImages.find((img) => img.id === props.profile.ref)
                  ?.url || "/headshot-placeholder.png"
              }
              alt={props.profile.firstName}
              className="modal-headshot-img"
            />
            <h2 id="modal-title" className="modal-title-text">
              {props.profile.firstName} {props.profile.middleName}{" "}
              {props.profile.lastName}
            </h2>
            <div className="modal-horizontal-bar" />
            <p className="modal-bio-text">{props.profile.bio}</p>
            <div className="contact-info-div">
              {props.profile.email && (
                <div className="contact-row-div">
                  <EmailIcon sx={emailContactIcon} />
                  <Tooltip title="Copy Email" placement="bottom">
                    <p
                      className="contact-text-maroon"
                      onClick={() => handleClick()}
                    >
                      : {props.profile.email}
                    </p>
                  </Tooltip>
                </div>
              )}
              {props.profile.link && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Go To LinkedIn" placement="bottom">
                    <LinkedInIcon
                      sx={contactIcon}
                      onClick={() => linkToggle(props.profile.link)}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        <Snackbar
          open={message}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Copied!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      </Box>
    </Modal>
  );
}

export default ProfileModal;