import React from 'react'
import { useState } from 'react'
import { TextField, Button,Typography, Box, Modal } from '@mui/material'
import { addOngoingProject } from '../Backend/research';
import Snackbar from '@mui/material/Snackbar';


function  AddProjectsModal(props) {
    const handleClose = () => props.setOpen(false);
    const [isSnackbar, setIsSnackbar] = useState(false);

    const closeSnackbar = () => {
      setIsSnackbar(false);
    };

    const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const project = {
      title: data.get('title'),
      subtitle: data.get('subtitle'),
      line_one: data.get('line_one'),
      line_two: data.get('line_two'),
    };
    addOngoingProject(data.get('title'),data.get('subtitle'),data.get('line_one'),data.get('line_two'));
    setIsSnackbar(true);
    setTimeout(() => {
      handleClose();
      window.location.reload();
  }, 2000);
  };

  return (
    <Modal
    open={props.open}
    onClose={handleClose}
    sx={{display:'flex', justifyContent:'center', 
        padding:'2em', overflow:'auto'
    }}
    >
      <Box
        sx={{
            background:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'90vw',
            height:'fit-content',
            borderRadius:'5px'
            
        }}
      >
        <div style={{width:'100%', background:'#5D1725',
             display:'flex', justifyContent:'center', 
             color:'white', padding:'1em',borderTopLeftRadius:'5px', borderTopRightRadius:'5px'}}>
            <Typography component="h1" variant="h5" >
            PROJECT INFORMATION
            </Typography>
        </div>
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'100%'
        }}
        >
            <div style={{width:'100%', padding:'1em'}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="subtitle"
                    label="Subtitle"
                    name="subtitle"
                    autoComplete="subtitle"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="line_one"
                    label="Line One"
                    name="line_one"
                    autoComplete="line_one"
                    multiline
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="line_two"
                    label="Line Two"
                    name="line_two"
                    autoComplete="line_two"
                />
            
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background:'black' }}
                >
                Submit
                </Button>
            </div>
        </Box>
        <Snackbar
          open={isSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Added Project!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      </Box>
    </Modal>
  )
}

export default AddProjectsModal;
