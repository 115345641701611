import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Home from "./Pages/Home";
import Members from "./Pages/Members";
import Research from "./Pages/Research";
import Publications from "./Pages/Publications";
import News from "./Pages/News";
import JoinUs from "./Pages/JoinUs";
import Links from "./Pages/Links";

function Routing() {
  const location = useLocation();
  return (
    <div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/members" element={<Members />} />
          <Route path="/research" element={<Research />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/links" element={<Links />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/news" element={<News />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default Routing;
//major client-side routing logic. When user navigates to a certain page, the computer knows where to route through this code.
//previously, in traditional htmls, the routing logic was handled by the server side.
