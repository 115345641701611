import {
  collection,
  addDoc,
  deleteDoc,
  doc,
} from "@firebase/firestore";
import { Timestamp } from 'firebase/firestore';
import { firestore } from "./firebase";
import {storage} from "./firebase"
import {uploadBytes,deleteObject} from "firebase/storage"


//function to get the news article

export const getNewsArticles = async () => {
  try {
    const querySnapshot = await firestore.collection("News").get();
    const articles = querySnapshot.docs.map((doc) => ({
      id: doc.id, // Fetches the unique ID of the document
      data: doc.data(), // Fetches the data of the document
      date: doc.data().date.toDate().toString(),
    }));
    // console.log("date: " + articles[0].date);
    return articles;
  } catch (error) {
    console.error("Error fetching news articles: ", error);
    return [];
  }
};



export const fetchImagesForArticles = async (articleIds) => {

  const storageRef = storage.ref();
  const downloadPromises = articleIds.map(async (id) => {
    const imageRef = storageRef.child(`/Images_News/${id}.png`);
    try {
      const url = await imageRef.getDownloadURL();
      console.log("File URL:", url);
      return { id, url };
    } catch (error) {
      console.error(`Error downloading file for article ID ${id}:`, error);
      return { id, url: null };
    }
  });

  const urls = await Promise.all(downloadPromises);
  return urls.filter((item) => item.url !== null);
};

export const addArticle = async (author,content,date_arg,link,title,image) => {

const date = new Date(date_arg);
const timestamp = Timestamp.fromDate(date);

  let article = {
    author:author,
    content:content,
    date:timestamp,
    link:link,
    title:title
  };

  try {
    const articleDocref = await addDoc(collection(firestore, "News"), article);
    const articleId = articleDocref.id;
    console.log("Article added successfully:" + articleId);
    const storageRef = storage.ref()
  let newsRef = storageRef.child(`Images_News/${articleId}.png`)        ///need to set up an id here.
  uploadBytes(newsRef, image).then((snapshot) => {
  console.log('Uploaded a blob or file!');
  })
  } 
   catch (error) {
    console.error("Error adding article", error);
  }

}






export const deleteArticle = async (ref) => {
  try {
    const storageRef = storage.ref()
    let newsRef = storageRef.child(`Images_News/${ref}.png`)        ///need to set up an id here.
    const articleDocref = doc(firestore, "News", ref);
    await deleteDoc(articleDocref);     //delete the article from the firestore.
    await deleteObject(newsRef);      //deletes the image from the storage.
    console.log("Article deleted successfully:", ref);
  } catch (error) {
      console.error("Error deleting publication", error);
  }
};
