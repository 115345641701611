import React, { useRef } from 'react';
import { toPng } from 'html-to-image';
import CustomComponent from './DecoratedHeadshot';
import { useEffect } from 'react';
import { addImage } from '../Backend/members';

const ConvertComponentToPng = ({ image, ...props }) => {
  const ref = useRef();

  const handleUploadImage = async () => {
    if (ref.current === null || props.id === null) {
      return;
    }

    try {
        console.log("after check: " + props.id);
      const dataUrl = await toPng(ref.current);
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const downloadURL = await addImage(blob, props.id);
      console.log("back from add Image");
      console.log("Image uploaded successfully. Download URL:", downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    if (props.submit) {
        console.log("submit is true??");
      handleUploadImage();
    }
  }, [props.submit]);
  
  return (
    <div>
      <div style={{ display:'flex', alignItems:"center", justifyContent:'center', width:'fit-content'}} ref={ref}>
        <CustomComponent image={image}/>
      </div>
    </div>
  );
};

export default ConvertComponentToPng;