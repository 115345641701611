import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import '../Styles/Home.css';
import DrawerNavbar from '../Components/DrawerNavbar';
import Footer from '../Components/Footer';

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);        //window.innerwidth gets the width of the device

  useEffect(() => {           
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);      

    return () => {
      window.removeEventListener('resize', handleResize);     //return cleans up code to remove the unexpected behaviour.
    };
  }, []);       //runs the code on mount as there is an empty array of dependency.


  return (
    <motion.div className='main-div'    //motion.div throws the animation on the whole div.
        initial={{opacity: 0}}
        animate={{opacity:'100%', transition:{duration:1.5}}}
        exit={{opacity: 0}}
        style={{position:'relative'}}
    >
      <Header/>
      {isMobile ? <DrawerNavbar /> : <Navbar />}
      <div className='home-content-div'>
          <img src='/mac-logo-maroon.png'  alt='mac-logo' className='mac-logo' />
          <h1 className='landing-h1'> Welcome to </h1>
          <h1 className='landing-h1'> Mississippi Agroclimatology Collaboratory </h1>
      </div>
      <video className='farm-vid'
          src='/farmvid.mp4' autoPlay loop muted
      />
      <div style={{ width:'100%', height:'100%', zIndex:'1000', position:'absolute'}}>
        <div style={{ width:'100%', zIndex:'1000', 
          position:'absolute', top:'100%' }}>
          <Footer/>
        </div>
      </div>
    </motion.div>
  )
}

export default Home