import {
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    updateDoc,
    doc,
  } from "@firebase/firestore";
  import { firestore } from "./firebase";

  export const getAllPublications = async () => {
    try {
      const getData = collection(firestore, "publications");
      const snapshot = await getDocs(getData);
      // const firstDoc = snapshot.docs[0];
      // console.log(firstDoc.data());
      const publications = [];
      snapshot.forEach((doc) => {
        publications.push({
            ref: doc.id,
            doi: doc.data().doi,
            issue: doc.data().issue,
            journal: doc.data().journal,
            pages: doc.data().pages,
            publisher: doc.data().publisher,
            title: doc.data().title,
            url: doc.data().url,
            volume: doc.data().volume,
            year: doc.data().year,
        });
      });
      console.log("All publications fetched:", publications);
      return publications;
    } catch (error) {
      throw new Error( error);
    }
  };

  export const addPublication = async (doi, issue, journal, pages, publisher, title, url, volume, year) => {
    let publication = {
      doi: doi,
      issue: issue,
      journal: journal,
      pages: pages,
      publisher: publisher,
      title: title,
      url: url,
      volume: volume,
      year: year,
    };
  
    try {
      const publicationDocRef = await addDoc(collection(firestore, "publications"), publication);
      const publicationId = publicationDocRef.id;
      console.log("Publication added successfully:" + publicationId);
  
      
    } catch (error) {
      console.error("Error adding publication", error);
    }
  };

  export const deletePublication = async (ref) => {
    try {
      const publicationDocRef = doc(firestore, "publications", ref);
      await deleteDoc(publicationDocRef);
      console.log("Publication deleted successfully:", ref);
    } catch (error) {
        console.error("Error deleting publication", error);
    }
  };

  export const editPublicationHeader = async (ref, newValue) => {
    try {
      const headerDocRef = doc(firestore, "publication-headers", ref);
      await updateDoc(headerDocRef, { value: newValue });
      console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error editing publication", error);
    }
  };

  export const getAllHeaders = async () => {
    try {
      const getData = collection(firestore, "publication-headers");
      const snapshot = await getDocs(getData);
      const headers = {};
      snapshot.forEach((doc) => {
        headers[doc.id] = doc.data().value;
      });
      return headers;
    } catch (error) {
      throw new Error(error);
    }
  };
