import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { deletePublication } from '../Backend/publications';
import { deleteOngoingProject } from '../Backend/research';

function DeleteAlert(props) {
    
    
    const handleYes = () => {
        props.setOpen(false);
        if (props.type == 'publication') {
          deletePublication(props.id);
        } else if (props.type == 'project') {
          deleteOngoingProject(props.id);
        }
        props.setIsDeleted(true);
        setTimeout(() => {
          window.location.reload();
      }, 2000);
    };

    const handleNo = () => {
        props.setOpen(false);
    };

    const handleClose = () => {
      props.setOpen(false);
    };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete this {props.type}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>YES</Button>
        <Button onClick={handleNo} autoFocus>
          NO
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAlert