import {React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import Navbar from '../Components/Navbar';
import DrawerNavbar from '../Components/DrawerNavbar';
import Header from '../Components/Header';
import DeleteAlert from '../Components/DeleteAlert';
import { getAllPublications, editPublicationHeader, getAllHeaders} from '../Backend/publications';
import LaunchIcon from '@mui/icons-material/Launch';
import { Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {TextField, Box, Button} from '@mui/material';
import AddPublicationModal from '../Components/AddPublicationModal';
import { Snackbar } from "@mui/material";
import { auth } from "../Backend/firebase"; //      just imported authentication feature from the firebase.
import { signOut } from "firebase/auth"; //
import '../Styles/Publications.css';
import Footer from '../Components/Footer';

function Publications() {
  const [publications, setPublications] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteRef, setDeleteRef] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [headers, setHeaders] = useState({});
  const [header1, setHeader1] = useState("");
  const [header2, setHeader2] = useState("");
  const [editHeader1, setEditHeader1] = useState(false);
  const [editHeader2, setEditHeader2] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const publicationList = await getAllPublications();
      setPublications(publicationList);
      console.log("user: " + auth.currentUser);
      const headerList = await getAllHeaders();
      const id1 = "header1";
      const id2 = "header2";
      setHeaders(headerList);
      setHeader1(headerList["header1"]);
      setHeader2(headerList["header2"]);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    const handleVisibilityChange = () => {      //handles if the user stays out of the tab for too long.
      if (document.visibilityState === "hidden") {      //document.visibilityState returns either 'visible' or 'hidden'
        signOut(auth)
          .then(() => {
            console.log("User signed out");
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      }
    };

    window.addEventListener("visibilitychange",handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const goToLink = (link) => {
    window.open(link, "_blank");
  };

    const handleAlert = (id) => {
        console.log("clicked delete button");
        setOpenAlert(true);
        console.log("alert: " + openAlert);
        setDeleteRef(id);
    }

    const handleInputChange1 = (event) => {
      setHeader1(event.target.value);
    };

    const handleInputChange2 = (event) => {
      setHeader2(event.target.value);
    };

  const closeSnackbar = () => {
    setIsDeleted(false);
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setEditHeader2(false);
    setHeader2(data.get("header2"));
    editPublicationHeader("header2", data.get("header2"));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setEditHeader1(false);
    setHeader2(data.get("header1"));
    editPublicationHeader("header1", data.get("header1"));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };




  return (
    <motion.div
      className="main-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%", transition: { duration: 1.5 } }}
      exit={{ opacity: 0 }}
    >
      {auth.currentUser && ( //renders this if the user is signed in.
        <div className="sticky-div">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => setOpenModal(true)}
            sx={{ mt: 3, mb: 2, background:'black', margin:'0' }}
            >
            Add Publications
          </Button>
        </div>
      )}
      <AddPublicationModal
        open={openModal}
        setOpen={setOpenModal}
        headers={headers} //to manipulate the state of the open variable through the child component.
      />
      <DeleteAlert
        open={openAlert}
        setOpen={setOpenAlert}
        id={deleteRef}
        setIsDeleted={setIsDeleted}
        type={"publication"}
      />
        <Header/>
        {isMobile ? <DrawerNavbar /> : <Navbar />}
        
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', position:'relative'}}>
            <img src='/argiculture-img.png'  alt='publications-img' className='group-img'/>
            <h2 className='pic-overlay-text'
            >PUBLICATIONS
            </h2>
        </div>
        <div style={{display:'flex', justifyContent:'center', 
        flexDirection:'column', marginTop:'2em', padding:'1em', gap:'1.5em'}}>
            <div> 
                 {editHeader1 ?
                 (
                  <Box
                    component="form"
                    onSubmit={handleSubmit1}
                  sx={{display:'flex', gap:'1em', alignItems:'center'}}>

                    <TextField
                      defaultValue={header1}
                      value={header1}
                      onChange={handleInputChange1}
                      sx={{marginBottom:'3px'}}
                      id="header1"
                      label="Year"
                      name="header1"
                      autoComplete="header1"
                      inputProps={{ pattern: "\\d{4}" }}
                    />
                    
                    <Button 
                    type="submit"
                      sx={{ color: 'white', background:'#5D1725',
                                      '&:hover': {
                        background: 'black',
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                      }
                    }}
                    variant="outlined">
                      Save Changes
                    </Button>
                  </Box>
                ):(
                  <div style={{display:'flex', gap:'1em'}}>
                 <h2 style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin:'0', color:'#5D1725',
                  fontSize:'22px'}}
                  >
                      {header1}
                  </h2>

                  {auth.currentUser && (
                    <Tooltip title='Edit' placement='bottom' >
                      <EditIcon 
                      onClick={() => setEditHeader1(true)}
                      sx={{ color:'#5D1725',
                                      '&:hover': {
                        color: 'black',
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                      }
                      }}/>
                    </Tooltip>
                  )}
                </div>
                ) }
                <div style={{width:"100%", height:'2px', background:'grey'}}/>
                <div style={{display:'flex', flexDirection:'column', gap:'1.5em', marginTop:'1.5em'}}>
                    {publications.filter(pub => pub.year === headers["header1"]).map(pub => (
                        pub.url ? (
                            <Tooltip title='Go To Article' placement='bottom'>
                              <div className='pub-div' >
                                  <div style={{width:'100%', display:'flex', flexDirection:'column'}} 
                                  onClick={() => goToLink(pub.url)}>
                                      <p className='pub-title'>
                                          {pub.title}
                                      </p>
                                      <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                          {pub.journal || pub.publisher} {pub.volume}{pub.issue && ` (${pub.issue})`}{pub.pages && `, ${pub.pages}`}
                                      </p>
                                      <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                          {pub.doi}
                                      </p>
                                  </div>
                                  {auth.currentUser && (
                                  <div className='delete-icon-div' onClick={() => handleAlert(pub.ref)}>
                                      <Tooltip title='Delete' placement='bottom'>
                                          <DeleteForeverIcon />
                                      </Tooltip>
                                  </div>
                                  )}
                              </div>
                            </Tooltip>
                        ) : (
                            <div className='non-clickable-pub-div' >
                                <div style={{width:'100%', display:'flex', flexDirection:'column'}} key={pub.title}>
                                    <p className='pub-title'>
                                        {pub.title}
                                    </p>
                                    <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                        {pub.journal || pub.publisher} {pub.volume}{pub.issue && ` (${pub.issue})`}{pub.pages && `, ${pub.pages}`}
                                    </p>
                                    <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                        {pub.doi}
                                    </p>
                                </div>
                                {auth.currentUser && (
                                    <div className='delete-icon-div' onClick={() => handleAlert(pub.ref)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}
                            </div>

                        )
                    ))}
                </div>
            </div>
            <div>
                 {editHeader2 ?
                 (
                  <Box
                    component="form"
                    onSubmit={handleSubmit2}
                  sx={{display:'flex', gap:'1em', alignItems:'center'}}>

                    <TextField
                      defaultValue={header2}
                      value={header2}
                      onChange={handleInputChange2}
                      sx={{marginBottom:'3px'}}
                      id="header2"
                      label="Year"
                      name="header2"
                      autoComplete="header2"
                      inputProps={{ pattern: "\\d{4}" }}
                    />
                    
                    <Button 
                    type="submit"
                      sx={{ color: 'white', background:'#5D1725',
                                      '&:hover': {
                        background: 'black',
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                      }
                    }}
                    variant="outlined">
                      Save Changes
                    </Button>
                  </Box>
                ):(
                  <div style={{display:'flex', gap:'1em'}}>
                 <h2 style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin:'0', color:'#5D1725',
                  fontSize:'22px'}}
                  >
                      {header2}
                  </h2>
                  {auth.currentUser && (
                    <Tooltip title='Edit' placement='bottom' >
                      <EditIcon 
                      onClick={() => setEditHeader2(true)}
                      sx={{ color:'#5D1725',
                                      '&:hover': {
                        color: 'black',
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                      }
                      }}/>
                    </Tooltip>
                  )}
                </div>
                ) }
                <div style={{width:"100%", height:'2px', background:'grey'}}/>
                <div style={{display:'flex', flexDirection:'column', gap:'1.5em', marginTop:'1.5em'}}>
                    {publications.filter(pub => pub.year === headers['header2']).map(pub => (
                    pub.url ? (
                        <Tooltip title='Go To Article' placement='bottom'>
                            <div className='pub-div' >
                                <div style={{width:'100%', display:'flex', flexDirection:'column'}} onClick={() => goToLink(pub.url)}>
                                    <p className='pub-title' style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', fontSize: '18px' }}>
                                        {pub.title}
                                    </p>
                                    <p style={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                        {pub.journal || pub.publisher} {pub.volume}{pub.issue && ` (${pub.issue})`}{pub.pages && `, ${pub.pages}`}
                                    </p>
                                    <p style={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                        {pub.doi}
                                    </p>
                                </div>
                                {auth.currentUser && (
                                <div className='delete-icon-div' onClick={() => handleAlert(pub.ref)}>
                                    <Tooltip title='Delete' placement='bottom'>
                                        <DeleteForeverIcon />
                                    </Tooltip>
                                </div>
                                )}
                            </div>
                        </Tooltip>
                    ) : (
                        <div className='non-clickable-pub-div' >
                            <div style={{width:'100%', display:'flex', flexDirection:'column'}}  key={pub.title}>
                                <p className='pub-title' style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', fontSize: '18px' }}>
                                    {pub.title}
                                </p>
                                <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                    {pub.journal || pub.publisher} {pub.volume}{pub.issue && ` (${pub.issue})`}{pub.pages && `, ${pub.pages}`}
                                </p>
                                <p style={{ fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', margin: '0', color: 'grey' }}>
                                    {pub.doi}
                                </p>
                            </div>
                            {auth.currentUser && (
                            <div className='delete-icon-div' onClick={() => handleAlert(pub.ref)}>
                                <Tooltip title='Delete' placement='bottom'>
                                    <DeleteForeverIcon />
                                </Tooltip>
                            </div>
                            )}
                        </div>
                    )
                    ))}
                </div>
            </div>
        </div>
        <div className='gs-link-div' onClick={() => goToLink('https://scholar.google.co.in/citations?hl=en&user=hqfX7HMAAAAJ&view_op=list_works')}>
            <p className='google-scholar-link' style={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'#5D1725'}}>
                Go to Google Scholar to view all publications
            </p>
            <LaunchIcon sx={{color:'#5D1725', fontSize:'medium'}}/>
        </div>
        <Snackbar
          open={isDeleted}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Deleted Publication"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      <Footer/>
    </motion.div>
  );
}

export default Publications;
