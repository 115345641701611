import React from 'react'
import { useState } from 'react'
import { TextField, Button,Typography, Box, Modal } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { addMember } from '../Backend/members'
import Snackbar from '@mui/material/Snackbar';
import ConvertComponentToPng from './ConvertComponentToPng';
import { connectStorageEmulator } from 'firebase/storage'




function AddMembersModal(props) {
    
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [imageError, setImageError] = useState(null);
    const [image, setImage] =  useState(null);
    const [submit, setSubmit] = useState(false);
    const [newRef, setNewRef] = useState(null);
    const [position, setPosition] = useState(null);
    const positions = [
      { label: 'Phd Student', value:'phd-students' },
      { label: 'Graduate Student', value: 'grad-students' },
      { label: 'Project Research Assistant', value: 'project-research-assistants'},
      { label: 'Collaborators', value: 'collaborators'}
    ];

    const handleClose = () => {
      props.setOpen(false);
      setImage(null);
    }
    const closeSnackbar = () => {
      setIsSnackbar(false);
    };

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const validTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
        if (!validTypes.includes(file.type)) {
          setImageError('File must be of type PNG, JPG, or SVG');
          setImage(null);
          return;
        }
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== img.height) {
            setImageError('Image must have the same width and height (ex: 540 x 540, 300 x 300).');
            setImage(null);
          } else {
            setImageError(null);
            setImage(file);
          }
        };
      }
    };
  

    const handleSubmit = async (event) => {
    event.preventDefault();             //prevents the default behaviour in the form submission i.e. refresh of the page which 
                                        //is necessary in SPAs made in react.
    const data = new FormData(event.currentTarget);     //gets the entire formdata in the form of object.
    if (imageError) {
      return;
    }
    const newId = await addMember(position, data.get('firstName'),
    data.get('middleName'),data.get('lastName'),data.get('email'),data.get('link'),data.get('bio'));
    setSubmit(true);
    setNewRef(newId);
    if(newId) {
      setIsSnackbar(true);
    }

    setTimeout(() => {
      handleClose();
      window.location.reload();
  }, 2100);
  };

  return (
    <Modal
    open={props.open}
    onClose={handleClose}
    sx={{display:'flex', justifyContent:'center', 
        padding:'2em', overflow:'auto'
    }}
    >
      <Box
        sx={{
            background:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'90vw',
            height:'fit-content',
            borderRadius:'5px'
            
        }}
      >
        <div style={{width:'100%', background:'#5D1725',
             display:'flex', justifyContent:'center', 
             color:'white', padding:'1em',borderTopLeftRadius:'5px', borderTopRightRadius:'5px'}}>
            <Typography component="h1" variant="h5" >
            MEMBERS INFORMATION
            </Typography>
        </div>
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'100%'
        }}
        >
        <div style={{width:'100%', padding:'1em'}}>
                <div style={{display:"flex", gap:'3em', width:'100%'}}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    />
                </div>
                <div style={{display:"flex", gap:'3em', width:'100%'}}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="middleName"
                      label="Middle Name"
                      name="middleName"
                    />
                    <Autocomplete
                    disablePortal
                    required
                    fullWidth
                    id="combo-box-demo"
                    options={positions}
                    onChange={(event, value) => setPosition(value.value)}
                    renderInput={(params) => 
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="position"
                      label="Position"
                      name="position"
                        {...params} />}
                  />
                </div>
                <div style={{display:"flex", gap:'3em',  width:'100%'}}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    />
                    <TextField
                    margin="normal"
                    fullWidth
                    id="link"
                    label="Link"
                    name="link"
                    />
                    
                </div>
                <div style={{display:"flex", gap:'3em',  width:'100%'}}>
                    <TextField
                    margin="normal"
                    fullWidth
                    id="bio"
                    label="Bio"
                    name="bio"
                    />
                    
                </div>
                <div style={{ display: "flex", gap: '3em', width: '100%', 
                  marginTop:'10px', marginBottom:'5px' }}>
                    <input
                        type="file"
                        accept="image/png, image/jpeg, image/svg+xml"
                        name="image"
                        onChange={handleImageChange}
                    />
                </div>
                {imageError && (
                    <Typography color="error" variant="body2">
                        {imageError}
                    </Typography>
                )}
                {image && (
                  <ConvertComponentToPng 
                  submit={submit} 
                  image={image}
                  id={newRef}/>
                )}
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background:'black' }}
                >
                Submit
                </Button>
            </div>
        </Box>
        <Snackbar
          open={isSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Added Member!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      </Box>
    </Modal>
  )
}

export default AddMembersModal
