import {React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import Navbar from '../Components/Navbar';
import DrawerNavbar from '../Components/DrawerNavbar';
import Header from '../Components/Header';
import ProfileModal from '../Components/ProfileModal';
import HeadProfileModal from '../Components/HeadProfileModal';
import { getAllPhdStudents, getAllGradStudents, 
  getAllProjectResearchAssistants, getAllCollaborators } from '../Backend/members';
import { auth } from '../Backend/firebase';
import { signOut } from "firebase/auth"; //
import { Button,Tooltip} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteAlertMember from '../Components/DeleteAlertMember';
import AddMembersModal from '../Components/AddMembersModal'
import { fetchImages } from "../Backend/members";
import "../Styles/Members.css";
import { Snackbar } from "@mui/material";
import ConvertComponentToPng from '../Components/ConvertComponentToPng';
import Footer from '../Components/Footer';

export const membersIDs = []; //an empty array to store the data.
export let fetchedImages = []; //an empty array to store the fetched images from the backend.

function Members() {

  const [phdStudents, setPhdStudents] = useState([]);
  const [gradStudents, setGradStudents] = useState([]);
  const [projectResearchAssistants, setProjectResearchAssistants] = useState(
    []
  );
  const [collaborators, setCollaborators] = useState([]);
  const [open, setOpen] = useState(false); //State variables: so must be used to render something as far as concerned.
  const [openHeadProfile, setOpenHeadProfile] = useState(false); //must be used to render something.
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
  const [imageURL, setImageURL] = useState([]); //to fetch the images of the members from the storage of firebase.
  const [openModal,setOpenModal] = useState(false);   //variable to manipulate the render the addmembermodal comp.
  const [openAlert, setOpenAlert] = useState(false);   //variable to open the dialog box to confirm whether the user wants to 
                                                      //delete or not.
  const [deleteRef, setDeleteRef] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [position,setPosition] = useState('');
  const [deletedMemberPosition, setDeletedMemberPosition] = useState('');


  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    bio: "",
    link: "",
  });

  const handleOpen = (profile) => {
    setProfile(profile);
    setOpen(true);
  };

  const handleOpenHeadProfile = () => {
    setOpenHeadProfile(true);
  };

  useEffect(() => {
    async function fetchData() {
      const phdStudentList = await getAllPhdStudents();
      setPhdStudents(phdStudentList);
      phdStudentList.forEach((student) => {
        membersIDs.push(student.ref);
      }); //push the ids of the members into the members array.

      const gradStudentList = await getAllGradStudents();
      gradStudentList.forEach((student) => {
        membersIDs.push(student.ref);
      });

      setGradStudents(gradStudentList);
      const projectResearchAssistantList =
        await getAllProjectResearchAssistants();

      projectResearchAssistantList.forEach((student) => {
        membersIDs.push(student.ref);
      });
      setProjectResearchAssistants(projectResearchAssistantList);
      const collaboratorList = await getAllCollaborators();

      collaboratorList.forEach((student) => {
        membersIDs.push(student.ref);
      }); //all of the members ids are pushed into an array named membersid.
      setCollaborators(collaboratorList);

      fetchedImages = await fetchImages(membersIDs);
      setImageURL(fetchedImages);
      // console.log(fetchedImages);
    }
    fetchData();
  }, []); //runs only on mount as there is an empty array of dependency for fetching the data.

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {      //handles if the user stays out of the tab for too long.
      if (document.visibilityState === "hidden") {      //document.visibilityState returns either 'visible' or 'hidden'
        signOut(auth)
          .then(() => {
            console.log("User signed out");
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      }
    };

    window.addEventListener("visibilitychange",handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleAlert = (id,role,e) => {
    e.stopPropagation();
    setDeleteRef(id);
    setDeletedMemberPosition(role);        //to prevent the syncing of the delete button and the main div of the profile section.
    console.log("clicked delete button");
    setOpenAlert(true);
    console.log("id to be deleted: " + id);
}

const closeSnackbar = () => {
  setIsDeleted(false);
};


  return (
    <motion.div
      className="main-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%", transition: { duration: 1.5 } }}
      exit={{ opacity: 0 }}
    >
      <Header />
      {isMobile ? <DrawerNavbar /> : <Navbar />}

      {auth.currentUser && ( //renders this if the user is signed in.
        <div className="sticky-div">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => setOpenModal(true)}
            sx={{ mt: 3, mb: 2, background:'black', margin:'0' }}
            >
            Add Members
          </Button>
        </div>
      )}

      <AddMembersModal 
      open = {openModal}
      setOpen = {setOpenModal}/>

      <DeleteAlertMember
        open={openAlert}
        setOpen={setOpenAlert}
        id={deleteRef}
        position={deletedMemberPosition}
        setIsDeleted={setIsDeleted}
        />

      <div className="main-pic-div">
        <img src="/group-img.png" alt="group-img" className="group-img" />
        <h2 className="pic-overlay-text">MEMBERS</h2>
      </div>
      <div className='main-content-box'>
        <div className='outer-profile-transition-div'>
          <div className='title-div'>
            <h2 className='title-text'>THE TEAM</h2>
          </div>
          <div className="title-bar" />
          <div
            className="main-profile-div"
            onClick={() => handleOpenHeadProfile()}
          >
            <img
              src="/prakash-jha.png"
              alt="prakash-jha"
              className="prakash-jha-img"
            />
            <h2 className="main-name-text">Prakash Kumar Jha</h2>
          </div>
        </div>
        <div className="outer-profile-transition-div">
          <div className="title-div">
            <h2 className="title-text">Ph.D. STUDENTS</h2>
          </div>
          <div className="title-bar" />
          <div className="outer-profiles-div">
            {phdStudents !== undefined &&
              (() => {
                const elements = [];
                let index = 0;

            while (index < phdStudents.length - 1) {
              const phdStudent = phdStudents[index];
              const nextPhdStudent = phdStudents[index + 1];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div' 
                  onClick={() =>  {handleOpen(phdStudent)}} 
                  >
                    <img  src={
                          imageURL.find((img) => img.id === phdStudent.ref)?.url || "/headshot-placeholder.png"
                        } 
                        alt={phdStudent.firstName}
                    className= 'headshot-img' />
                    <h2 className='member-text'>
                      {phdStudent.firstName} {phdStudent.middleName} {phdStudent.lastName}
                    </h2>

                    {auth.currentUser && (
                                    <div  className='delete-button-div' onClick={(e) => handleAlert(phdStudent.ref,"phd-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon sx={{zIndex:'5'}}/>
                                        </Tooltip>
                                    </div>
                                )}      {/* the delete icon shows up*/}
                  </div>
                  {nextPhdStudent && (
                    <div className='profile-div'
                    onClick={() => {handleOpen(nextPhdStudent)}}>
                      <img src={
                            imageURL.find(
                              (img) => img.id === nextPhdStudent.ref
                            )?.url || "/headshot-placeholder.png"
                          }  alt={nextPhdStudent.firstName}
                      className= 'headshot-img' />
                      <h2 className='member-text'>
                        {nextPhdStudent.firstName} {nextPhdStudent.middleName} {nextPhdStudent.lastName}
                      </h2>
                      {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(nextPhdStudent.ref,"phd-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}      {/* the delete icon shows up*/}
                    </div>
                  )}
                </div>
              );

              index += 2;
            }
            if (index === phdStudents.length - 1) {
              const phdStudent = phdStudents[index];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div' 
                  onClick={() => {handleOpen(phdStudent)}}
                  >
                    <img src={
                            imageURL.find(
                              (img) => img.id === phdStudent.ref
                            )?.url || "/headshot-placeholder.png"
                          } alt={phdStudent.firstName} 
                    className= 'headshot-img'/>
                    <h2 className='member-text'>
                      {phdStudent.firstName} {phdStudent.middleName} {phdStudent.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(phdStudent.ref,"phd-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon className='delete-button'/>
                                        </Tooltip>
                                    </div>
                                )}      {/* the delete icon shows up*/}
                  </div>
                  <div className='profile-div-transparent'>
                    <img src='transparent.png'  className= 'headshot-img' alt="transparent"/>
                  </div>
                </div>
              );
            }

                return <>{elements}</>; //pushed all the code into an array and rendered it at the last ; denotes the end of the Phdstudent.
              })()}
          </div>
        </div>
        <div className="outer-profile-transition-div">
          <div className="title-div">
            <h2 className="title-text">MASTERS STUDENTS</h2>
          </div>
          <div className="title-bar" />
          <div className="outer-profiles-div">
            {gradStudents !== undefined &&
              (() => {
                const elements = [];
                let index = 0;

            while (index < gradStudents.length - 1) {
              const gradStudent = gradStudents[index];
              const nextGradStudent = gradStudents[index + 1];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div'
                  onClick={() => {handleOpen(gradStudent)}}>
                    <img src={
                            imageURL.find(
                              (img) => img.id === gradStudent.ref
                            )?.url || "/headshot-placeholder.png"
                          }
                          alt={gradStudent.firstName} 
                    className= 'headshot-img' />
                    <h2 className='member-text'>
                      {gradStudent.firstName} {gradStudent.middleName} {gradStudent.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(gradStudent.ref,"grad-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                  </div>
                  {nextGradStudent && (
                    <div className='profile-div'
                    onClick={() => {handleOpen(nextGradStudent)}}>
                      <img  src={
                            imageURL.find(
                              (img) => img.id === nextGradStudent.ref
                            )?.url || "/headshot-placeholder.png"
                          }
                          alt={nextGradStudent.firstName} 
                      className= 'headshot-img' />
                      <h2 className='member-text'>
                        {nextGradStudent.firstName} {nextGradStudent.middleName} {nextGradStudent.lastName}
                      </h2>
                      {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(nextGradStudent.ref,"grad-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}      {/* the delete icon shows up*/}
                    </div>
                  )}
                </div>
              );

              index += 2;
            }
            if (index === gradStudents.length - 1) {
              const gradStudent = gradStudents[index];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div'
                  onClick={() => {handleOpen(gradStudent)}}>
                    <img  src={
                            imageURL.find(
                              (img) => img.id === gradStudent.ref
                            )?.url || "/headshot-placeholder.png"
                          }
                          alt={gradStudent.firstName}
                          className="headshot-img"
                        />
                        <h2 className="member-text">
                          {gradStudent.firstName} {gradStudent.middleName}{" "}
                          {gradStudent.lastName}
                        </h2>
                        {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(gradStudent.ref,"grad-students",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                      </div>
                      <div className="profile-div-transparent">
                        <img
                          src="transparent.png"
                          className="headshot-img"
                          alt="transparent"
                        />
                      </div>
                    </div>
                  );
                }

                return <>{elements}</>;
              })()}
          </div>
        </div>
        <div className="outer-profile-transition-div">
          <div className="title-div">
            <h2 className="title-text">PROJECT RESEARCH ASSISTANTS</h2>
          </div>
          <div className="title-bar" />
          <div className="outer-profiles-div">
            {projectResearchAssistants !== undefined &&
              (() => {
                const elements = [];
                let index = 0;

            while (index < projectResearchAssistants.length - 1) {
              const projectResearchAssistant = projectResearchAssistants[index];
              const nextProjectResearchAssistant = projectResearchAssistants[index + 1];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div'
                  onClick={() => {handleOpen(projectResearchAssistant)}}>
                    <img  
                    src={
                          imageURL.find(
                            (img) => img.id === projectResearchAssistant.ref
                          )?.url || "/headshot-placeholder.png"
                        } 
                    alt={projectResearchAssistant.firstName}  className= 'headshot-img' />
                    <h2 className='member-text'>
                      {projectResearchAssistant.firstName} {projectResearchAssistant.middleName} {projectResearchAssistant.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(projectResearchAssistant.ref,"project-research-assistants",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                  </div>
                  {nextProjectResearchAssistant && (
                    <div className='profile-div'
                    onClick={() => {handleOpen(nextProjectResearchAssistant)}}>
                      <img 
                      src={
                        imageURL.find(
                          (img) => img.id === nextProjectResearchAssistant.ref
                        )?.url || "/headshot-placeholder.png"
                      } 
                      alt={nextProjectResearchAssistant.firstName} className= 'headshot-img' />
                      <h2 className='member-text'>
                        {nextProjectResearchAssistant.firstName} {nextProjectResearchAssistant.middleName} {nextProjectResearchAssistant.lastName}
                      </h2>
                      {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(nextProjectResearchAssistant.ref,"project-research-assistants",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                    </div>
                  )}
                </div>
              );

              index += 2;
            }
            if (index === projectResearchAssistants.length - 1) {
              const projectResearchAssistant = projectResearchAssistants[index];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div'
                  onClick={() => {handleOpen(projectResearchAssistant)}}>
                    <img src={
                          imageURL.find(
                            (img) => img.id === projectResearchAssistant.ref
                          )?.url || "/headshot-placeholder.png"
                        } 
                    alt={projectResearchAssistant.firstName}  className= 'headshot-img'/>
                    <h2 className='member-text'>
                      {projectResearchAssistant.firstName} {projectResearchAssistant.middleName} {projectResearchAssistant.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(projectResearchAssistant.ref,"project-research-assistants",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )} 
                  </div>
                  <div className='profile-div-transparent'>
                    <img src='transparent.png'  className= 'headshot-img' alt="transparent"/>
                  </div>
                </div>
              );
            }

                return <>{elements}</>;
              })()}
          </div>
        </div>
        <div className="outer-profile-transition-div">
          <div className="title-div">
            <h2 className="title-text">COLLABORATORS</h2>
          </div>
          <div className='title-bar'/>
          <div className='outer-profiles-div-modified'>
            {collaborators !== undefined && (() => {
            const elements = [];
            let index = 0;

            while (index < collaborators.length - 1) {
              const collaborator = collaborators[index];
              const nextCollaborator = collaborators[index + 1];
              elements.push(
                <div key={index} className='row-profile-div'>
                  <div className='profile-div' 
                  onClick={() => {handleOpen(collaborator)}}>
                    <img src={
                          imageURL.find(
                            (img) => img.id === collaborator.ref
                          )?.url || "/headshot-placeholder.png"
                        } 
                         alt={collaborator.firstName}  
                    className= 'headshot-img'/>
                    <h2 className='member-text'>
                      {collaborator.firstName} {collaborator.middleName} {collaborator.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(collaborator.ref,"collaborators",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                  </div>
                  {nextCollaborator && (
                    <div className='profile-div' 
                    onClick={() => {handleOpen(nextCollaborator)}}>
                      <img src={
                          imageURL.find(
                            (img) => img.id === nextCollaborator.ref
                          )?.url || "/headshot-placeholder.png"
                        } 
                        alt={nextCollaborator.firstName}
                      className= 'headshot-img' />
                      <h2 className='member-text'>
                        {nextCollaborator.firstName} {nextCollaborator.middleName} {nextCollaborator.lastName}
                      </h2>
                      {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(nextCollaborator.ref,"collaborators",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                    </div>
                  )}
                </div>
              );

              index += 2;
            }
            if (index === collaborators.length - 1) {
              const collaborator = collaborators[index];
              elements.push(
                <div key={index} className='row-profile-div'>
                  
                  <div className='profile-div'
                   onClick={() => {handleOpen(collaborator)}}>
                    <img 
                    src={
                      imageURL.find(
                        (img) => img.id === collaborator.ref
                      )?.url || "/headshot-placeholder.png"
                    } 
                     alt={collaborator.firstName} 
                    className= 'headshot-img' />
                    <h2 className='member-text'>
                      {collaborator.firstName} {collaborator.middleName} {collaborator.lastName}
                    </h2>
                    {auth.currentUser && (
                                    <div className='delete-button-div' onClick={(e) => handleAlert(collaborator.ref,"collaborators",e)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon />
                                        </Tooltip>
                                    </div>
                                )}  
                  </div>
                  <div className='profile-div-transparent'>
                    <img src='transparent.png'  className= 'headshot-img'/>
                  </div>
                </div>
              );
            }

                return <>{elements}</>;
              })()}
          </div>
        </div>
        
      </div>
      <Snackbar
          open={isDeleted}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Deleted Member"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />

      <ProfileModal
      open={open}
      setOpen={setOpen}
      profile={profile}
      />
      <HeadProfileModal
      open={openHeadProfile}
      setOpen={setOpenHeadProfile}/>
      <Footer/>
    </motion.div>
  );
}

export default Members;