import React from "react";
import '../Styles/Header.css';


function Header() {
  return (
    <div className='home-header-div'
      >
        <div className="msstate-logo-div">
          <img src="/msstate_horizontal_white.svg"  alt="msstate-horizontal-white-logo" className="msstate-logo"/>
        </div>
        <div className="header-mac-logo-div">
          <img src="/mac-logo-horizontal-white.svg"  alt="mac-logo-horizontal-white-logo" className="header-mac-horizontal-logo" />
          <img src="/mac-logo-mobile-white.svg"  alt="mac-logo-mobile-white-logo" className="mac-logo-mobile-white-logo" />
        </div>
    </div>
  );
}

export default Header;
