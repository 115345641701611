import React from "react";
import { motion } from "framer-motion";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import "../Styles/News.css";
import DrawerNavbar from "../Components/DrawerNavbar";
import { getNewsArticles, fetchImagesForArticles } from "../Backend/news";
import { useEffect, useState } from "react";
import { auth } from "../Backend/firebase"; 
import { signOut } from "firebase/auth";
import { Button } from "@mui/material";
import AddNewsModal from "../Components/AddNewsModal";
import { Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteAlertNews from "../Components/DeleteAlertNews";
import {Snackbar} from "@mui/material";
import Footer from "../Components/Footer";



function News() {
  const [articles, setArticles] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteRef, setDeleteRef] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
 
  useEffect(() => {
    const fetchData = async () => {
      const fetchedArticles = await getNewsArticles();
      setArticles(fetchedArticles);

      const articleIds = fetchedArticles.map((article) => article.id);
      const fetchedImages = await fetchImagesForArticles(articleIds);
      setImageURLs(fetchedImages);
    };

    fetchData();
  }, []);


  useEffect(() => {
    //responsiveness
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {      
      if (document.visibilityState === "hidden") {   
        signOut(auth)
          .then(() => {
            console.log("User signed out");
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      }
    };

    window.addEventListener("visibilitychange",handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  
  const handleAlert = (id) => {
    console.log("clicked delete button");
    setOpenAlert(true);
    console.log("alert: " + openAlert);
    setDeleteRef(id);
}

  const closeSnackbar = () => {
  setIsDeleted(false);
  };

  return (
    <motion.div
      className="main-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%", transition: { duration: 1.5 } }}
      exit={{ opacity: 0 }}
    >

{auth.currentUser && ( //renders this if the user is signed in.
        <div className="sticky-div">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => setOpenModal(true)}
            sx={{ mt: 3, mb: 2, background:'black', margin:'0' }}
            >
            Add News
          </Button>
        </div>
      )}
      <AddNewsModal
        open={openModal}
        setOpen={setOpenModal} //to manipulate the state of the open variable through the child component.
      />
      <DeleteAlertNews
        open={openAlert}
        setOpen={setOpenAlert}
        id={deleteRef}
        setIsDeleted={setIsDeleted}
        />

      <Header/>
      {isMobile ? <DrawerNavbar /> : <Navbar />}
      <div className='main-pic-div'>
        <img src='/News_background.jpg'  alt='news-img' className='group-img' />
        <h2  className='pic-overlay-text'>
          NEWS
        </h2>
      </div>

      <div style={{display:'flex', alignItems:'center',justifyContent:'center', flexDirection:'column'}} >
        <div className='title-div'>
            <h2 className='title-text'>LATEST NEWS</h2>
        </div>
        <div className='title-bar'/>
        <div className="sub-container">
          {articles.map((article) => (
            <div className="containers-sub-container" key={article.id}>
              <div className="news-img-div">
                <img className="news-img"
                src={
                  imageURLs.find((img) => img.id === article.id)?.url ||
                  "/placeholder.png"
                }
                alt={article.data.title}
                />
              </div>
              <div className="news-content-div">
                {article.data.link ? ( //if link of the article exists, then this links the page to it.
                  <div className="sub">
                    <p className="news-title">{article.data.title}</p>
                    <p className="news-content"> {article.data.content}</p>
                    <a
                      href={`${article.data.link}`}
                      target="blank"
                      className="readmore"
                    >
                      READ MORE {'>>>'}
                    </a>
                  </div>
                ) : (
                  <div>
                    <p className="news-title">{article.data.title}</p>
                    <p className="news-content"> {article.data.content}</p>
                  </div>
                )}
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <p className="author">Author: {article.data.author}</p>
                  <p className="timestamp">{article.date.split(' ')[0]}, {article.date.split(' ')[1]} {article.date.split(' ')[2]} {article.date.split(' ')[3]}</p>
                </div>
                {auth.currentUser && (
                                    <div className='delete-button-div-news' onClick={() => handleAlert(article.id)}>
                                        <Tooltip title='Delete' placement='bottom'>
                                            <DeleteForeverIcon sx={{scale:'1.2'}}/>
                                        </Tooltip>
                                    </div>
                                    )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Snackbar
          open={isDeleted}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Deleted Article"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      <Footer/>
    </motion.div>
  );
}

export default News;