import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import AdminLoginModal from './AdminLoginModal';

function DrawerNavbar() {
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = useState(false);

    const navigate = useNavigate();  

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleModalClick = () => {
    setOpenModal(true);
  };
  const DrawerList = (
    <Box sx={{ width: 220, background:'#5D1725', height:'100%' }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate('/home');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'HOME'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate('/research');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'RESEARCH'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton  onClick={() => {
                navigate('/members');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'MEMBERS'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate('/publications');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'PUBLICATIONS'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate('/news');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'NEWS'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate('/joinus');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'JOIN US'} />
            </ListItemButton>
        </ListItem >
        <ListItem disablePadding>
            <ListItemButton onClick={()=>{
                navigate('/links');
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'LINKS'} />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={()=>{
                handleModalClick()
            }}>
                <ListItemText sx={{fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', color:'white', textDecoration:'underline'}} primary={'ADMIN LOGIN'} />
            </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Button sx={{padding:'0'}} onClick={toggleDrawer(true)}><ListIcon sx={{fontSize:'2.2em', color:'#5D1725'}}/></Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <AdminLoginModal
      open={openModal}
      setOpen={setOpenModal} />
    </div>
  );
}

export default DrawerNavbar