import React from 'react'
import { useState } from 'react'
import { TextField, Button,Typography, Box, Modal } from '@mui/material'
import { addPublication } from '../Backend/publications';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete'



function AddPublicationModal(props) {
    const handleClose = () => props.setOpen(false);
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);

    const years = [
      { label: props.headers['header1'], value: props.headers['header1'] },
      { label: props.headers['header2'], value:props.headers['header2'] },
    ];

    const closeSnackbar = () => {
      setIsSnackbar(false);
    };

    const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const publication = {
      doi: data.get('doi'),
      issue: data.get('issue'),
      journal: data.get('journal'),
      pages: data.get('pages'),
      publisher: data.get('publisher'),
      title: data.get('title'),
      url: data.get('url'),
      volume: data.get('volume'),
      year: data.get('year'),
    };
    addPublication(data.get('doi'),data.get('issue'),data.get('journal'), data.get('pages'),
    data.get('publisher'), data.get('title'), data.get('url'),data.get('volume'),data.get('year'));
    setIsSnackbar(true);
    setTimeout(() => {
      handleClose();
      window.location.reload();
  }, 2000);
  };

  return (
    <Modal
    open={props.open}
    onClose={handleClose}
    sx={{display:'flex', justifyContent:'center', 
        padding:'2em', overflow:'auto'
    }}
    >
      <Box
        sx={{
            background:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'90vw',
            height:'fit-content',
            borderRadius:'5px'
            
        }}
      >
        <div style={{width:'100%', background:'#5D1725',
             display:'flex', justifyContent:'center', 
             color:'white', padding:'1em',borderTopLeftRadius:'5px', borderTopRightRadius:'5px'}}>
            <Typography component="h1" variant="h5" >
            PUBLICATION INFORMATION
            </Typography>
        </div>
        <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'100%'
        }}
        >
            <div style={{width:'100%', padding:'1em'}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                />
                <div style={{display:"flex", gap:'3em', width:'100%'}}>
                      <Autocomplete
                      disablePortal
                      required
                      fullWidth
                      id="combo-box-demo"
                      options={years}
                      onChange={(event, value) => setSelectedYear(value)}
                      renderInput={(params) => 
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="year"
                        label="Year"
                        name="year"
                        autoComplete="year"
                         {...params} />}
                    />
                    <TextField
                    margin="normal"
                    fullWidth
                    id="journal"
                    label="Journal"
                    name="journal"
                    autoComplete="journal"
                    />
                </div>
                <div style={{display:"flex", gap:'3em',  width:'100%'}}>
                    <TextField
                    margin="normal"
                    fullWidth
                    id="publisher"
                    label="Publisher"
                    name="publisher"
                    autoComplete="publisher"
                    />
                    <TextField
                    margin="normal"
                    fullWidth
                    id="volume"
                    label="Volume"
                    name="volume"
                    autoComplete="volume"
                    />
                    
                </div>
                <div style={{display:"flex", gap:'3em',  width:'100%'}}>
                    <TextField
                    margin="normal"
                    fullWidth
                    id="issue"
                    label="Issue"
                    name="issue"
                    autoComplete="issue"
                    />
                    <TextField
                    margin="normal"
                    fullWidth
                    id="pages"
                    label="Pages"
                    name="pages"
                    autoComplete="pages"
                    />
                    
                </div>
                <div style={{display:"flex", gap:'3em',  width:'100%'}}>
                    <TextField
                    margin="normal"
                    fullWidth
                    id="doi"
                    label="DOI"
                    name="doi"
                    autoComplete="doi"
                    autoFocus
                    />
                    <TextField
                    margin="normal"
                    fullWidth
                    id="url"
                    label="URL"
                    name="url"
                    autoComplete="url"
                    />
                </div>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background:'black' }}
                >
                Submit
                </Button>
            </div>
        </Box>
        <Snackbar
          open={isSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Added Publication!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Position the Snackbar on the right side
        />
      </Box>
    </Modal>
  )
}

export default AddPublicationModal
