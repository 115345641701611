import {React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import Navbar from '../Components/Navbar';
import DrawerNavbar from '../Components/DrawerNavbar';
import Header from '../Components/Header';
import { Tooltip } from '@mui/material';
import { Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import '../Styles/JoinUs.css';
import { auth } from "../Backend/firebase"; 
import { signOut } from "firebase/auth";
import Footer from '../Components/Footer';



function JoinUs() {
  const [message, setMessage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setMessage(true);
    
  };


  const closeSnackbar = () => {
    setMessage(false);
  };

  useEffect(() => {         //code to check the type of device.
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {      
      if (document.visibilityState === "hidden") {   
        signOut(auth)
          .then(() => {
            console.log("User signed out");
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      }
    };

    window.addEventListener("visibilitychange",handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  

  return (
    <motion.div className='main-div'
        initial={{opacity: 0}}
        animate={{opacity:'100%', transition:{duration:1.5}}}
        exit={{opacity: 0}}
    >
        <Header/>
        {isMobile ? <DrawerNavbar /> : <Navbar />}
        <div className='main-pic-div'>
          <img src='/joinus-img.png'  alt='joinus-img' className='group-img' />
          <h2  className='pic-overlay-text'>
            JOIN US
          </h2>
        </div>
        <div style={{width:'94%', display:'flex', borderRadius:'5px', border: '1.5px solid #5D1725',
        padding:'1em',boxShadow: '5px 5px 0px 1px #5D1725',
         justifyContent:'center', alignItems:'center', margin: '0 auto', marginBottom:'4em', marginTop:'4em'
        }}>
          <div style={{width:'100%'}}>
            <h3 className='join-us-title'>
              Interested in Joining MAC Lab?
            </h3>
            <div className='title-bar'/>
            <div style={{marginTop:'1.5em', marginBottom:'2.5em'}}>
              <p className='join-us-text'>The Mississippi Agroclimatology Collaboratory (MAC) comprises 
                  members from diverse fields, including Agronomy, Physiology, Agricultural Engineering, Computer Sciences, Geosciences, and Environmental Sciences. If the research at the MAC Lab excites 
                  you, we invite you to contact us about future opportunities. 
                  We welcome undergraduates, graduates, postdocs, and interns to join us!
              </p>
            </div>
            <h3 className='join-us-title'>
              Contact Us:
            </h3>
            <div className='title-bar'/>
            <div style={{display:'flex', flexDirection:'column', gap:'0.25em'}}>
              <div style={{display:'flex',alignItems:'center', marginTop:'1.5em',marginBottom:'0.5em'}}>
                <p className='contact-name-text'>
                  Dr. Prakash Kumar Jha
                </p>
              </div>
              <div style={{display:'flex', gap:'12px', alignItems:'center'}}>
                <p className='join-us-text'>
                  Email: pj442@msstate.edu
                </p>
                <Tooltip title='copy' placement='right'>
                  <ContentCopyIcon sx={{fontSize:'medium', '&:hover': {
                      cursor: 'pointer'
                    }}}
                    onClick={() => handleCopy('pj442@msstate.edu')}
                    />
                </Tooltip>
              </div>
              <div style={{display:'flex', gap:'12px', alignItems:'center'}}>
                <p className='join-us-text'>
                  Phone: (662) 325-2311
                </p>
                <Tooltip title='copy' placement='right'>
                  <ContentCopyIcon sx={{fontSize:'medium', '&:hover': {
                      cursor: 'pointer'
                    }}}
                    onClick={() => handleCopy('(662) 325-2311')}      //the function sets the message state variable true so that the snackbar renders.
                    />
                </Tooltip>
              </div>
            </div>
          </div>  
        </div>
        <Snackbar
        open={message}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message="Copied!"
        anchorOrigin={{vertical: 'bottom',horizontal: 'right' }} // Position the Snackbar on the right side
        />
      <Footer/>
    </motion.div>
  )
}

export default JoinUs
