import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { deleteMember } from '../Backend/members';

function DeleteAlertMember(props) {
    
    
    const handleYes = async () => {
        props.setOpen(false);
        console.log("position in delete: " + props.position);
        const retVal = await deleteMember(props.id,props.position);
        if(retVal) {
          props.setIsDeleted(true);
        }
     setTimeout(() => {
          window.location.reload();
      }, 1000);
    };

    const handleNo = (event) => {
        props.setOpen(false);
    };

    const handleClose = () => {
      props.setOpen(false);
    };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete this member?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>YES</Button>
        <Button onClick={handleNo} autoFocus>
          NO
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAlertMember