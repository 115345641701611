import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Tooltip } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import copy from "copy-to-clipboard";
import "../Styles/Members.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #5D1725",
  borderRadius: "0.5em",
  boxShadow: "5px 5px 0px 2px #5D1725",
  overflow: "auto",
  padding: "1em !important",
  "&:focus": {
    outline: "none",
  },
};

const linkIcon = {
  color: "#5D1725",
  fontSize: "2vw",
  "&:hover": {
    color: "black",
    cursor: "pointer",
  },
  "@media (max-width: 500px)": {
    fontSize: "4vw",
  },
};

const contactIcon = {
  color: "#5D1725",
  fontSize: "2vw",
  "@media (max-width: 500px)": {
    fontSize: "4vw",
  },
};

function HeadProfileModal(props) {
  const [message, setMessage] = useState(false);

  const handleClick = (text) => {
    // if (navigator.clipboard && navigator.clipboard.writeText) {
    //   navigator.clipboard.writeText("pj442@msstate.edu");
    //   setMessage(true);
    copy(text);
    setMessage(true);
  };
  const handleClose = () => props.setOpen(false);

  const closeSnackbar = () => {
    setMessage(false);
  };

  const linkToggle = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="main-modal-div"
    >
      <Box sx={modalStyle}>
        <div className="outer-modal-div">
          <CloseIcon onClick={handleClose} sx={linkIcon} />
        </div>
        <div className="outer-bio-div">
          <div className="bio-div">
            <img
              src={`/prakash-jha.png`}
              className="modal-headshot-img"
              alt="prakash-jha"
            />
            <h2 id="modal-title" className="modal-title-text">
              Prakash Kumar Jha
            </h2>
            <p className="job-title-text">
              Assistant Professor - Agricultural Climatology
            </p>
            <div className="modal-horizontal-bar" />
            <p className="modal-bio-text">
              My research interest focuses on investigating the influence of
              climate and agronomic management on crop growth and development. I
              seek to understand the complexities of agricultural systems,
              integrating crop simulation models, remote sensing, and climate
              forecasting to formulate decision-support systems for better
              management strategies of inputs in crop production systems.
            </p>
            <div className="contact-info-div">
              <div className="contact-row-div">
                <EmailIcon sx={contactIcon} />
                <Tooltip title="Copy Email" placement="bottom">
                  <p
                    className="contact-text-maroon"
                    onClick={() => handleClick("pj442@msstate.edu")}
                  >
                    : pj442@msstate.edu
                  </p>
                </Tooltip>
              </div>

              <div className="contact-row-div">
                <PhoneIcon sx={contactIcon} />
                <Tooltip title="Copy Phone Number" placement="bottom">
                  <p
                    className="contact-text-maroon"
                    onClick={() => handleClick("(662) 325-2311")}
                  >
                    : (662) 325-2311
                  </p>
                </Tooltip>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="LinkedIn" placement="bottom">
                  <LinkedInIcon
                    sx={linkIcon}
                    onClick={() =>
                      linkToggle("https://www.linkedin.com/in/pjhamsu/")
                    }
                  />
                </Tooltip>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Google Scholar" placement="bottom">
                  <SchoolIcon
                    sx={linkIcon}
                    onClick={() =>
                      linkToggle(
                        "https://scholar.google.co.in/citations?user=hqfX7HMAAAAJ&hl=en"
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={message}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          message="Copied!"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
      </Box>
    </Modal>
  );
}

export default HeadProfileModal;