import React from "react";
import { useNavigate } from "react-router-dom";
import ResourceMenu from "./ResourceMenu";
import "../Styles/Navbar.css";

function Navbar() {
    const navigate = useNavigate();  
    return (
    <div className='main-navbar'>
        <h5 className='navbar-h5' onClick={() => {
            navigate('/home');
        }}> 
        HOME 
        </h5>
        <h5 className='navbar-h5' onClick={() => {
            navigate('/research');
        }}> 
        RESEARCH 
        </h5>
        <h5 className='navbar-h5' onClick={() => {
            navigate('/members');
        }}> 
        MEMBERS 
        </h5>
        <ResourceMenu />
        <h5 className='navbar-h5' onClick={() => {
            navigate('/news');
        }}> NEWS </h5>
        <h5 className='navbar-h5' onClick={() => {
            navigate('/joinus');
        }}>  
        JOIN US 
        </h5>
    </div>
  );
}

export default Navbar;
