import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  getDocs,
} from "@firebase/firestore";
import { firestore } from "./firebase";
import { storage } from "./firebase";

export const getAllPhdStudents = async () => {
  try {
    const getData = collection(firestore, "phd-students");
    const snapshot = await getDocs(getData);
    // const firstDoc = snapshot.docs[0]
    const phdStudents = [];
    snapshot.forEach((doc) => {
      phdStudents.push({
        ref: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        middleName: doc.data().middleName,
        bio: doc.data().bio,
        email: doc.data().email,
        link: doc.data().link,
      });
    });
    console.log("All phd-students fetched:", phdStudents);
    return phdStudents;
  } catch (error) {
    throw new Error( error);
  }
};

export const getAllGradStudents = async () => {
  try {
    const getData = collection(firestore, "grad-students");
    const snapshot = await getDocs(getData);
    // const firstDoc = snapshot.docs[0];
    const gradStudents = [];
    snapshot.forEach((doc) => {
      gradStudents.push({
        ref: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        middleName: doc.data().middleName,
        bio: doc.data().bio,
        email: doc.data().email,
        link: doc.data().link,
      });
    });
    console.log("All grad-students fetched:", gradStudents);
    return gradStudents;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllProjectResearchAssistants = async () => {
  try {
    const getData = collection(firestore, "project-research-assistants");
    const snapshot = await getDocs(getData);
    const projectResearchAssistants = [];
    snapshot.forEach((doc) => {
      projectResearchAssistants.push({
        ref: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        middleName: doc.data().middleName,
        bio: doc.data().bio,
        email: doc.data().email,
        link: doc.data().link,
      });
    });
    console.log("All project-research-assistants fetched:", projectResearchAssistants);
    return projectResearchAssistants;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllCollaborators = async () => {
  try {
    const getData = collection(firestore, "collaborators");
    const snapshot = await getDocs(getData);
    const collaborators = [];
    snapshot.forEach((doc) => {
      collaborators.push({
        ref: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        middleName: doc.data().middleName,
        bio: doc.data().bio,
        email: doc.data().email,
        link: doc.data().link,
      });
    });
    console.log("All collaborators fetched:", collaborators);
    return collaborators;
  } catch (error) {
    throw new Error(error);
  }
};


//fetch images from the storage of the database.

export const fetchImages = async (memberIDs) => {
  const storageRef = storage.ref(); 
  const downloadPromises = memberIDs.map(async (id) => {
    const imageRef = storageRef.child(`/Images_Members/${id}.png`);
    try {
      const url = await imageRef.getDownloadURL();
      return { id, url };
    } catch (error) {
      console.error(`Error downloading file for article ID ${id}:`, error);
      return { id, url: null };
    }
  });

  const urls = await Promise.all(downloadPromises);
  return urls.filter((item) => item.url !== null);
};

export const addImage = async (image, id) => {
  try {
    // Create a reference to the file location
    console.log("in add image: " + id);
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`Images_Members/${id}.png`);

    // Upload the image
    const snapshot = await imageRef.put(image);
    console.log("check 1: " + id);
    // Get the download URL of the uploaded image
    const downloadURL = await snapshot.ref.getDownloadURL();
    console.log("check 2: " + id);
    console.log("Image uploaded successfully. Download URL:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const addMember = async (position,fname,midname,lname,email,link,bio) => {
  console.log("collection: " + position);
  let member = {
    bio: bio,
    email: email,
    firstName: fname,
    lastName:lname,
    link: link,
    middleName: midname,
    };

  try {
    console.log("collection: " + position);
    const memberDocRef = await addDoc(collection(firestore, position), member);
    const memberId = memberDocRef.id;
    console.log("Member added successfully:" + memberId);
    return memberId;
  }
  catch (error) {
    console.error("Error adding member", error);
    return null;
  }
};   

export const deleteImage = async (id) => {
  try {
    // Create a reference to the file location
    console.log("in delete image: " + id);
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`Images_Members/${id}.png`);

    // Delete the image
    imageRef.delete();
    console.log("Image deleted successfully: " + id);
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};

export const deleteMember = async (ref,position) => {
  try {
    console.log("deleting member: " + ref);
    const memberDocRef = doc(firestore, position, ref);
    const val = await deleteDoc(memberDocRef);
    console.log("Member deleted successfully:", val);
    console.log("deleting image: ", ref);
    deleteImage(ref);
    return val;
  }
   catch (error) {
      console.error("Error deleting member", error);
      return null;
  }
};
