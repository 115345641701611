import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom';
import AdminLoginModal from './AdminLoginModal';
import { auth } from '../Backend/firebase';
import { signOut } from 'firebase/auth';

function ResourceMenu() {
  const navigate = useNavigate();  
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClick = () => {
    setOpenModal(true);
  };

  return (
    <div>
      <h5 className='navbar-h5'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        RESOURCES
        </h5>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
            navigate('/publications');
        }}
        sx={{color:'#5D1725',fontSize:'14px',fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif', fontWeight:'bold',  borderBottom:'1px solid lightgrey'}}>
            PUBLICATIONS
        </MenuItem>
        <MenuItem onClick={()=>{navigate('/links')}}
        sx={{color:'#5D1725', fontSize:'14px',fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',fontWeight:'bold', borderBottom:'1px solid lightgrey'}}>
            LINKS
        </MenuItem>

        {auth.currentUser==null ? (
        <MenuItem onClick={()=>{handleModalClick()}}
        sx={{color:'#5D1725', fontSize:'14px',fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',fontWeight:'bold', borderBottom:'1px solid lightgrey'}}>
            ADMIN LOGIN
        </MenuItem>) : (
           <MenuItem onClick={()=>{
            signOut(auth);
            setTimeout(window.location.reload(),2000)   //reloads the window after 2 sec.
          }}
           sx={{color:'#5D1725', fontSize:'14px',fontFamily:'"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',fontWeight:'bold', borderBottom:'1px solid lightgrey'}}>
               LOGOUT
           </MenuItem>
        )}

      </Menu>
      <AdminLoginModal
      open={openModal}
      setOpen={setOpenModal} />
    </div>
  )
}

export default ResourceMenu
