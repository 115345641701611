import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import DrawerNavbar from "../Components/DrawerNavbar";
import '../Styles/Links.css'
import Footer from "../Components/Footer";



function Links() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);      //sets the isMobile state variable true if the innerwidth of the window is less than 650.

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <motion.div
      className="main-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%", transition: { duration: 1.5 } }}
      exit={{ opacity: 0 }}
      style={{ position: "relative",
        backgroundColor:'#E5E4E2'	
        // 
       }}
    >
      <Header />
      {isMobile ? <DrawerNavbar /> : <Navbar />}
      <div className="coming-soon-container">
      <div className="coming-soon-content">
        <p className="coming-soon-text">COMING</p>
        <p className="soon-text">SOON</p>
        <p className="stay-tuned-text">STAY TUNED!</p>
      </div>
    </div>
    <Footer/>
    </motion.div>
  );
}

export default Links;
